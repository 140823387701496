import React, { Component, Fragment } from "react";
import { getCountries } from "../../actions/country";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import sectIndicator from "../../images/sect_indicator.svg";

import actionProjectCompanySerial from "../../actions/projectCompanySerial";
import pako from 'pako';

// Material table
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import DialogModalGlobal from "./DialogModalGlobal";

import LoadPage from "../Load/LoadPage";

import NumberFormat from "react-number-format";

import multipleUsersIcon from "../../images/multiple-users.png";

import {
  faSortUp,
  faSortDown,
  faSort,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

import { getWithdrawalInformation } from "../../actions/project";

import { bindActionCreators } from "redux";

import { platform } from "../../Style/index";

const actions = {
  getCountries,
};

class ProjectVintageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoad: true,
      projectSerialYear: [],
      projectSerialYearFilter: [],
      page: 0,
      rowsPerPage: 10,
      searchText: "",
      sortConfig: { key: null, direction: "asc" },
      modalCommentsGlobal: false,
      comment: [],
    };
  }

  sortData = (key) => {
    const { projectSerialYear, sortConfig, projectSerialYearFilter, page, rowsPerPage } =
      this.state;
    let direction = "asc";

    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }


    this.setState({
      sortConfig: { key, direction },
    });
  };

  componentDidMount() {
    getWithdrawalInformation().then((response) => {
      if (response.status !== 1) {
        return this.props.history.push("/404");
      }

      this.setState({
        pageLoad: false,
        projectSerialYear: response.projectSerialYear,
        projectSerialYearFilter: response.projectSerialYear,
      });
    });
  }

  handleSearch = (event) => {
    const { projectSerialYear } = this.state;

    this.setState({
      searchText: event.target.value,
      page: 0,
      projectSerialYearFilter: projectSerialYear.filter(
        (row) =>
          this.setClearString(JSON.stringify(row))
            .toLowerCase()
            .indexOf(this.setClearString(event.target.value).toLowerCase()) > -1
      ),
    });
  };

  setClearString = (string) => {
    if (string) {
      string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return string;
    } else {
      return "";
    }
  };

  onClickDownload = (typeSelect, selectedInfo) => {
    const { transfers, emitcertifications, projectSerialYear } = this.state;
    this.setState({
      pageLoad: true,
    });
    let data = {
      emitcertifications: projectSerialYear.map((row) => ({
        "Año Vintage": row.year,
        "Cod. Vintage": row.serial.toString().replace(/\s+/g, ""),
        Totales: row.total,
        Buffer: row.buffer,
        Netos: row.initial,
        Retenidos: row.withheld,
        "En cuentas de terceros": row.otherAccounts,
        Retirados: row.cancelled,
        "Disponibles totales del proyecto": Math.max(
          0,
          Number(row.net) - Number(row.release) - Number(row.cancelled)
        ),
        "Disponibles del proponente del proyecto":
          Math.max(
            0,
            Number(row.net) - Number(row.release) - Number(row.cancelled)
          ) - row.otherAccounts,
      })),
      typeSelect,
      name: "projects_vintage_list",
    }

    const compressedData = pako.gzip(JSON.stringify(data))
    const base64Data = btoa(String.fromCharCode(...compressedData));

    actionProjectCompanySerial
      .formatGeneralDownload({data: base64Data})
      .then((response) => {
        if (response.url) {
          const link = document.createElement("a");
          link.href = response.url;
          link.download = "projects_vintage_list.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.setState({
            pageLoad: false,
          });
        } else {
          this.setState({
            pageLoad: false,
          });
        }
      });
  };

  renderSortableHeader = (key, label) => {
    const { sortConfig } = this.state;
    return (
      <TableCell
        className="colcx-table-cell-header"
        style={{
          verticalAlign: "middle",
          width: 150,
          cursor: "pointer",
        }}
        onClick={() => this.sortData(key)}
      >
        {label}{" "}
        {sortConfig.key === key ? (
          sortConfig.direction === "asc" ? (
            <FontAwesomeIcon icon={faSortUp} />
          ) : (
            <FontAwesomeIcon icon={faSortDown} />
          )
        ) : (
          <FontAwesomeIcon icon={faSort} />
        )}
      </TableCell>
    );
  };



  getSortedData = () => {
    const {page, rowsPerPage, projectSerialYearFilter, sortConfig} = this.state
    return projectSerialYearFilter
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => {
      const valueA = a[sortConfig.key];
      const valueB = b[sortConfig.key];

      if (sortConfig.key === "ownerAvailable") {
        const diffA =
          Math.max(0, Number(a.net) - Number(a.release) - Number(a.cancelled)) -
          a.otherAccounts;
        const diffB =
          Math.max(0, Number(b.net) - Number(b.release) - Number(b.cancelled)) -
          b.otherAccounts;

        return sortConfig.direction === "asc" ? diffA - diffB : diffB - diffA;
      }
      if (sortConfig.key === "partialQty") {
        const diffA = Math.max(
          0,
          Number(a.net) - Number(a.release) - Number(a.cancelled)
        );
        const diffB = Math.max(
          0,
          Number(b.net) - Number(b.release) - Number(b.cancelled)
        );

        return sortConfig.direction === "asc" ? diffA - diffB : diffB - diffA;
      }

      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortConfig.direction === "asc" ? valueA - valueB : valueB - valueA;
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        return sortConfig.direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === "boolean" && typeof valueB === "boolean") {
        return sortConfig.direction === "asc"
          ? valueA === valueB
            ? 0
            : valueA
            ? -1
            : 1
          : valueA === valueB
          ? 0
          : valueA
          ? 1
          : -1;
      }

      return 0;
    })
  }


  render() {
    const { t, token } = this.props;
    const {
      projectSerialYear,
      pageLoad,
      comment,
      modalCommentsGlobal,
      projectSerialYearFilter,
      page,
      rowsPerPage,
    } = this.state;
    return (
      <div>
        {document.body.classList.add("new-background")}
        <Container
          style={{
            minHeight: "100vh",
            marginTop: token ? "10px" : "150px",
          }}
        >
          <LoadPage load={pageLoad} />
          <DialogModalGlobal
            t={t}
            loadPage={(control) => {
              this.setState({ pageLoad: control });
            }}
            projectId={this.props.match.params.projectId}
            view={this.state.modalCommentsGlobal}
            downloadDocument={false}
            tittle={t("tableQtyOnAccounts.comments.tittle")}
            message={""}
            comment={""}
            columns={["broker", "nit", "qty"].map((row) => ({
              label: `tableQtyOnAccounts.comments.${row}`,
              value: row,
            }))}
            consult={() => {}}
            commentDialog={"commentDialog"}
            setComment={comment}
            buttons={[
              {
                value: `${t("buttons.close")}`,
                onclick: () => {
                  this.setState({
                    modalCommentsGlobal: false,
                  });
                },
              },
            ]}
          />

          <Row>
            <Col
              xs={12}
              sm={12}
              md={6}
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={sectIndicator}
                  alt="Indicator"
                  style={{ marginRight: "0px", width: "18%" }}
                />
                <h2
                  style={{
                    color: "#59B089",
                    fontSize: "2.8rem",
                    fontWeight: "700",
                    lineHeight: "98.5px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                >
                  {t("Vintages")}
                </h2>
              </div>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Form.Control
                onChange={(event) => {
                  this.handleSearch(event);
                }}
                value={this.state.searchText}
                placeholder={`${t("searchText")}...`}
              />
            </Col>
          </Row>

          <TableContainer
            sx={{ maxHeight: "70vh", borderRadius: "20px" }}
            style={{
              borderRadius: "20px",
              fontFamily: "Montserrat",
              background: "white",
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                borderRadius: "20px",
                fontFamily: "Montserrat",
                background: "white",
              }}
            >
              <TableHead className="colcx-table">
                <TableRow className="colcx-table">
                  {this.renderSortableHeader("year", "Año Vintage")}
                  {this.renderSortableHeader("serial", "Cod. Vintage")}
                  {this.renderSortableHeader("total", `${t("Totales")}`)}
                  {this.renderSortableHeader("buffer", `${t("Búfer")}`)}
                  {this.renderSortableHeader(
                    "initial",
                    `${t("bonusCertification")}`
                  )}
                  <TableCell
                    className="colcx-table-cell-header"
                    style={{ verticalAlign: "middle", width: "10%" }}
                  >
                    {t("Retenidos")}
                  </TableCell>
                  {this.renderSortableHeader(
                    "otherAccounts",
                    `${t("bonusOtherAccounts")}`
                  )}
                  {this.renderSortableHeader(
                    "cancelled",
                    `${t("bonusCancellation")}`
                  )}
                  {this.renderSortableHeader(
                    "partialQty",
                    `${t("Disponibles totales del proyecto")}`
                  )}
                  {this.renderSortableHeader(
                    "ownerAvailable",
                    `${t("Disponibles del proponente del proyecto")}`
                  )}

                  {false && (
                    <TableCell
                      className="colcx-table-cell-header"
                      style={{ verticalAlign: "middle", width: "10%" }}
                    >
                      {t("Colcers Reservados")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.getSortedData()
                    .map((row, key) => {
                      return (
                        <TableRow>
                          <TableCell className="colcx-table-cell-info">
                            {row.year}
                          </TableCell>
                          <TableCell className="colcx-table-cell-info">
                            {row.serial}
                          </TableCell>

                          <TableCell className="colcx-table-cell-info">
                            <NumberFormat
                              value={row.total}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              isNumericString={false}
                            />
                          </TableCell>
                          <TableCell className="colcx-table-cell-info">
                            <NumberFormat
                              value={row.buffer}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              isNumericString={false}
                            />
                          </TableCell>

                          <TableCell className="colcx-table-cell-info">
                            <NumberFormat
                              value={row.net}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              isNumericString={false}
                            />
                          </TableCell>
                          <TableCell className="colcx-table-cell-info">
                            <NumberFormat
                              value={row.withheld}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              isNumericString={false}
                            />
                          </TableCell>

                          <TableCell className="colcx-table-cell-info">
                            <div style={{ display: "flex", width: "100%" }}>
                              <div
                                style={{
                                  width: "80%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <NumberFormat
                                  value={row.otherAccounts}
                                  displayType={"text"}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  isNumericString={false}
                                />
                              </div>
                              <div style={{ width: "20%" }}>
                                {row.otherAccounts !== 0 && (
                                  <Button
                                    size="sm"
                                    style={{
                                      ...platform.buttonSuccess,
                                      fontSize: "0.875rem",
                                      padding: "7px, 22px, 7px, 22px",
                                      borderRadius: "37px",
                                      borderColor: "inherit",
                                    }}
                                    // variant={
                                    //   certificated.isCanceled ? "secondary" : "info"
                                    // }
                                    title={t("buttons.preview")}
                                    onClick={() =>
                                      this.setState({
                                        modalCommentsGlobal: true,
                                        comment: row.otherAccountsDetails,
                                      })
                                    }
                                  >
                                    <img
                                      style={{ width: "15px", height: "15px" }}
                                      src={multipleUsersIcon}
                                    />
                                  </Button>
                                )}
                              </div>
                            </div>
                          </TableCell>

                          <TableCell className="colcx-table-cell-info">
                            <NumberFormat
                              value={row.cancelled}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              isNumericString={false}
                            />
                          </TableCell>
                          <TableCell className="colcx-table-cell-info">
                            <NumberFormat
                              value={Math.max(
                                0,
                                Number(row.net) -
                                  Number(row.release) -
                                  Number(row.cancelled)
                              )}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              isNumericString={false}
                            />
                          </TableCell>
                          <TableCell className="colcx-table-cell-info">
                            <NumberFormat
                              value={
                                Math.max(
                                  0,
                                  Number(row.net) -
                                    Number(row.release) -
                                    Number(row.cancelled)
                                ) - row.otherAccounts
                              }
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              isNumericString={false}
                            />
                          </TableCell>
                          {false && (
                            <TableCell className="colcx-table-cell-info">
                              {row.otherAccounts !== 0 && (
                                <Button
                                  size="sm"
                                  style={{
                                    ...platform.buttonSuccess,
                                    fontSize: "0.875rem",
                                    padding: "7px, 22px, 7px, 22px",
                                    borderRadius: "37px",
                                    borderColor: "inherit",
                                  }}
                                  // variant={
                                  //   certificated.isCanceled ? "secondary" : "info"
                                  // }
                                  title={t("buttons.preview")}
                                  onClick={() =>
                                    this.setState({
                                      modalCommentsGlobal: true,
                                      comment: row.otherAccountsDetails,
                                    })
                                  }
                                >
                                  <img
                                    style={{ width: "15px", height: "15px" }}
                                    src={multipleUsersIcon}
                                  />
                                </Button>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            style={{ background: "white" }}
            count={projectSerialYearFilter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => {
              this.setState({ page: newPage });
            }}
            onRowsPerPageChange={(event) => {
              this.setState({
                rowsPerPage: +event.target.value,
                page: 0,
              });
            }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Dropdown>
              <Dropdown.Toggle
                style={platform.buttonSuccess}
                id="dropdown-basic"
              >
                {t("myPosition.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => this.onClickDownload("csv", "vintages")}
                >
                  {" "}
                  {t("myPosition.excel")}
                </Dropdown.Item>
                {false && (
                  <>
                    <Dropdown.Item onClick={() => this.onClickDownload("pdf")}>
                      {" "}
                      {t("myPosition.pdf")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.onClickDownload("csv")}>
                      {" "}
                      {t("myPosition.csv")}
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const mapStateToProps = ({ user, general }) => ({
  token: user.token,
  countries: general.countries,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProjectVintageList)
);
