import React from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "./components/Error/Error404";
//Routers Account User
//import MyAccount from "./components/Security/MyAccount";
import ChangePassword from "./components/Security/ChangePassword";
import Login from "./components/Security/Login";
import MyAccount from "./components/Security/MyAccount";
import passwordRecovery from "./components/Security/passwordRecovery";
//Routers applications
import Register from "./components/Security/Register";
import MyProfile from "./components/Security/MyProfile";
import Notifications from "./components/Notifications/Notifications";
import GlobalConsultation from "./components/GlobalConsultation/GlobalConsultation";
import Initiatives from "./components/GlobalConsultation/Initiatives";
import { routes } from "./conf/";
import ProjectVintageList from "./components/Project/ProjectVintageList";
import ProjectEmitCertificationGlobalList from "./components/Project/ProjectEmitCertificationGlobalList";
import AboutUs from "./components/Contact/AboutUs";

const Routers = ({ token }) => {
  return (
    <Switch>
      {document.body.classList.remove("new-background")}
      {!token && <Route path="/register" exact component={Register} />}
      {!token && <Route path="/login" exact component={Login} />}
      {!token && (
        <Route
          path="/global-consultation"
          exact
          render={() => <GlobalConsultation token={token ? true : false} />}
        />
      )}
      {!token && (
        <Route path="/global-consultation/:id" exact component={Initiatives} />
      )}
      {token && (
        <Route
          path="/global-consultation"
          exact
          render={() => <GlobalConsultation token={token ? true : false} />}
        />
      )}
      {token && (
        <Route path="/global-consultation/:id" exact component={Initiatives} />
      )}

      {!token && (
        <Route path="/password-recovery" exact component={passwordRecovery} />
      )}
      {token && (
        <Route path="/change-password" exact component={ChangePassword} />
      )}
      {token && <Route path="/my-account" exact component={MyAccount} />}
      {token && <Route path="/my-company" exact component={MyAccount} />}
      {token && <Route path="/my-profile" exact component={MyProfile} />}
      {token && <Route path="/vintages" exact component={ProjectVintageList} />}
      {token && <Route path="/about-us" exact component={() => <AboutUs token={token ? true : false}/>} />}
      {token && <Route path="/withdraw" exact component={ProjectEmitCertificationGlobalList} />}
      {token && (
        <Route
          path="/login"
          exact
          component={(props) => {
            return props.history.push("/");
          }}
        />
      )}

      {
        //token && <Route path="/notifications" exact component={Notifications} />
      }

      {routes(token).map((permit) => (
        <Route
          key={permit.path && permit.path}
          path={permit.path && permit.path }
          exact
          component={permit.component && permit.component}
        />
      ))}
      {/* <Route component={Error404} /> */}
    </Switch>
  );
};

export default Routers;
