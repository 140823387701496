import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class Helper extends Component {
  render() {
    const { t, text, placement } = this.props;
    return (
      <div className="helper">
        <div
          style={{
            background: "#59B089",
            color: "#fff",
            lineHeight: 1,
            cursor: "pointer",
            width: 20,
            height: 20,
            padding: 3,
            fontSize: 15,
            borderRadius: 5,
            display: "inline-block",
            marginBottom: 5,
            verticalAlign: "middle",
            marginLeft: "0.5rem",
            textAlign: "center",
          }}
          className="helper-content"
        >
          <b>!</b>
        </div>
        <div
          className="helper-content__information"
          id={`tooltip-top`}
          style={{ textAlign: "center" }}
        >
          <div
            style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "10px" }}
          >
            <span style={{ textAlign: "center", fontSize: "0.775rem" }}>
              {t(text)}
            </span>
          </div>
          <div style={{height: "10px", background: "transparent", display: "flex", justifyContent: "center"}}>
            {/* Triangle */}
            <div style={{
              width: 0,
              height: 0,
              borderLeft: '5px solid transparent',
              borderRight: '5px solid transparent',
              borderTop: '8px solid rgba(0, 0, 0, 0.7)'
            }}></div>

          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Helper);
