import React, { Component, Fragment } from "react";
import { Col, Form } from "react-bootstrap";
import { getTypeDocumentsActives } from "../../actions/typeDocument";
import Select from "./Select";

class DocumentNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryId: this.props.countryId,
      typesDocuments: [],
      typeDocument: "1",
      documentNumber: "",
      documentNumberMessage: "",
      redemtion: false,
      verificationDigit: "",
      isVerificationDigit: false,
      formatDocumentNumber: this.props.formatDocumentNumber,
    };
  }

  componentWillReceiveProps(
    {
      countryId,
      formatDocumentNumber,
      clearForm = false,
      formOnChange,
      redemtion = false,
    },
    nextState
  ) {
    this.setState({ redemtion: redemtion });
    if (
      this.state.countryId &&
      this.state.countryId.toString() !== countryId.toString()
    ) {
      this.getTypeDocuments(countryId);
      this.setState({ countryId, formatDocumentNumber, documentNumber: "" });
    }
    if (!this.state.countryId && countryId) {
      this.getTypeDocuments(countryId);
      this.setState({ countryId, formatDocumentNumber, documentNumber: "" });
    }
    if (clearForm) {
      this.setState({ documentNumber: "", verificationDigit: "" });
    }
  }

  componentDidMount() {
    this.getTypeDocuments(this.state.countryId);
  }

  getTypeDocuments = (countryId) => {
    const { typeDocumentName, formOnChange } = this.props;
    if (countryId) {
      getTypeDocumentsActives(countryId).then((response) => {
        if (response.status === 1) {
          const typesDocuments = response.typesDocuments.map((element) => ({
            value: element.id,
            label: element.description,
            data: { isVerificationDigit: Boolean(element.isVerificationDigit) },
          }));
          this.setState({
            typesDocuments,
            typeDocument:
              typesDocuments.length > 0
                ? typesDocuments.length > 1 &&
                  (typeDocumentName === "typeDocumentlegal" ||
                    typeDocumentName === "typeDocumentContact")
                  ? 1
                  : typesDocuments[0].value
                : 1,
            isVerificationDigit:
              typesDocuments.length > 0
                ? typesDocuments[0].data.isVerificationDigit
                : false,
          });

          const select = document.getElementById(typeDocumentName);
          const event = new Event("change");
          select.dispatchEvent(event);
          formOnChange(typeDocumentName)(event);
        }
      });
    }
  };
  onChangeDigit =
    (nameOrigin, nameComponent = "") =>
    (event) => {
      const { formOnChange } = this.props;
      this.setState({
        [nameOrigin]: event.target.value,
      });
      formOnChange(nameOrigin)(event);
    };
  onChange =
    (nameOrigin, nameComponent = "") =>
    (event) => {
      const { formOnChange } = this.props;
      let isVerificationDigit = !this.props.representation
        ? this.state.isVerificationDigit
        : false;
      if (nameComponent === "typeDocument") {
        const optionElement =
          event.target.childNodes[event.target.selectedIndex];
        const option = JSON.parse(optionElement.getAttribute("data-json"));
        isVerificationDigit = option.isVerificationDigit;

        this.setState({
          [nameComponent]: event.target.value,
          isVerificationDigit,
          documentNumber: "",
        });
        formOnChange(nameOrigin)(event);
        event.target.value = "";
        formOnChange(this.props.documentNumberName)(event);
      }

      // this.setState({
      //   [nameComponent]: isVerificationDigit
      //     ? event.target.value.toString().replace(/[^0-9]/g, "")
      //     : event.target.value,
      //   isVerificationDigit,
      //   documentNumber: this.state.documentNumber
      // });
    };

  calcularDigitoVerificacion = (nit) => {
    let factores = [71, 67, 59, 53, 47, 43, 41, 37, 29, 23, 19, 17, 13, 7, 3];
    let suma = 0;
    let nitStr = nit.toString().padStart(15, "0");
    for (let i = 0; i < nitStr.length; i++) {
      suma += parseInt(nitStr.charAt(i)) * factores[i];
    }
    let residuo = suma % 11;
    let digitoVerificacion = residuo > 1 ? 11 - residuo : residuo;
    return digitoVerificacion;
  };
  onChangeDocumentNumber = (event) => {
    const formatDocumentNumber = this.state.formatDocumentNumber
    
      ? this.state.formatDocumentNumber
      : "[]";
    const regExp = new RegExp(formatDocumentNumber, "g");
    const {
      formOnChange,
      documentNumberName = "documentNumber",
      verificationDigitName,
    } = this.props;

    let documentNumber =
      (!this.props.representation && this.state.isVerificationDigit) ||
      this.state.typeDocument === "1" ||
      this.state.typeDocument === 1 ||
      (this.state.typeDocument === 2 && this.props.representation)
        ? event.target.value
            .toString()
            .replace(/[^0-9]/g, "")
            .replace(regExp, ".")
        : event.target.value.toString().replace(/[^0-9a-zA-Z./-\s]/g, "");
    
    this.setState({ documentNumber });

    const verificationDigitCalculation =
      this.state.isVerificationDigit && documentNumber.length >= 11
        ? this.calcularDigitoVerificacion(
            parseInt(documentNumber.replace(/\./g, ""))
          )
        : "";

    this.setState({ verificationDigit: verificationDigitCalculation });

    event.target.value = verificationDigitCalculation;
    formOnChange(verificationDigitName)(event);

    event.target.value = documentNumber;

    formOnChange(documentNumberName)(event);
  };

  render() {
    const {
      typeDocument,
      typesDocuments,
      documentNumber,
      isVerificationDigit,
      verificationDigit,
      documentNumberMessage
    } = this.state;
    const {
      typeDocumentName = "typeDocument",
      verificationDigitName = "verificationDigit",
      formValidation = {},
      translation,
      classTypeDocument = {
        xs: 12,
      },
      classDocumentNumber = {
        xs: 12,
      },
    } = this.props;
    return (
      <Fragment>
        <Col {...classTypeDocument}>
          <Select
            id={typeDocumentName}
            isInvalid={formValidation.typeDocument}
            options={
              this.props.representation
                ? typesDocuments.filter((ele) => ele && ele.value !== 2)
                : this.state.redemtion
                ? [
                    {
                      value: 1,
                      label: "Cédula de ciudadadanía",
                    },
                    {
                      value: 2,
                      label: "NIT",
                      data: { isVerificationDigit: Boolean(1) },
                    },

                    {
                      value: 5,
                      label: "Otro",
                    },
                  ]
                : typesDocuments
            }
            label={translation("formRegister.documentType")}
            onChange={this.onChange(typeDocumentName, "typeDocument")}
            value={typeDocument}
            disabled={this.props.disabled}
          />
        </Col>
        <Col {...classDocumentNumber}>
          <Form.Group>
            <Form.Label>
              {translation("formRegister.documentNumber")}
            </Form.Label>
            <br />

            <Form.Control
              style={{
                maxWidth: isVerificationDigit ? "68%" : "100%",
                display: "inline-block",
              }}
              isInvalid={formValidation.documentNumber}
              type="text"
              placeholder={translation("formRegister.documentNumber")}
              onChange={this.onChangeDocumentNumber}
              value={documentNumber}
              autoComplete="autoCompleteOff"
              maxLength={
                this.state.typeDocument &&
                parseInt(this.state.typeDocument) === 1
                  ? 13
                  : isVerificationDigit
                  ? 11
                  : 24
              }
              disabled={this.props.disabled}
            />
            {documentNumberMessage != "" &&
            <p style={{color: "red"}}>{documentNumberMessage}</p>}
            {!this.props.representation && isVerificationDigit && (
              <Form.Control
                style={{
                  maxWidth: "30%",
                  display: "inline-block",
                  marginLeft: "2%",
                }}
                isInvalid={formValidation.verificationDigit}
                type="text"
                // placeholder={translation("formRegister.verificationDigit")}
                onChange={
                  false &&
                  this.onChangeDigit(verificationDigitName, "verificationDigit")
                }
                value={verificationDigit}
                autoComplete="autoCompleteOff"
                maxLength={1}
                disabled={true || this.props.disabled}
              />
            )}
          </Form.Group>
        </Col>
      </Fragment>
    );
  }
}

export default DocumentNumber;
