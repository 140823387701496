import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const AboutUs = ({ token }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div style={{ marginTop: token ? "0px" : "10%", marginBottom: "3rem" }}>
      <h2
        style={{
          color: "#59B089",
          fontSize: "2.8rem",
          fontWeight: "700",
          lineHeight: "98.5px",
          textAlign: "left",
          textTransform: "none",
        }}
      >
        Acerca de la plataforma
      </h2>
     <p style={{marginTop: 0, marginBottom: "8pt"}}>La plataforma de registro de COLCX es provista por XM, empresa Colombiana que gestiona y administra informaci&oacute;n de los mercados y que cuenta con amplia experiencia en el desarrollo y gesti&oacute;n de tecnolog&iacute;as de &uacute;ltima generaci&oacute;n, basadas en Blockchain, para la administraci&oacute;n y certificaci&oacute;n de activos digitales de terceros.</p>
<p style={{marginTop: 0, marginBottom: "8pt"}}>La plataforma est&aacute; configurada para atender los procesos y requisitos del est&aacute;ndar COLCX, para que todos los actores de un proyecto puedan reportar informaci&oacute;n, cargar documentos y efectuar las aprobaciones requeridas.</p>
<p style={{marginTop: 0, marginBottom: "8pt"}}>Como su nombre lo indica, blockchain designa a una cadena de bloques. Estos son contenedores digitales enlazados entre s&iacute;, donde se almacena informaci&oacute;n que requiere alta seguridad (como contratos o transacciones). El conjunto de bloques conforma un libro contable digital cuya informaci&oacute;n es compartida en la red de usuarios. Este libro contable es descentralizado y no est&aacute; alojado en un servidor central, est&aacute; distribuido en m&uacute;ltiples nodos. La informaci&oacute;n contenida por cada bloque de la blockchain est&aacute; protegida por algoritmos criptogr&aacute;ficos seguros, por lo que no es posible la manipulaci&oacute;n de la informaci&oacute;n contenida (esto le otorga la inmutabilidad al sistema).</p>
<p style={{marginTop: 0, marginBottom: "8pt"}}>An&aacute;logamente blockchain funciona como un notario digital. Una vez que la informaci&oacute;n es certificada, los documentos dentro de plataforma no se pueden modificar. Este registro infalsificable le otorga a la plataforma confiabilidad y eficiencia para certificar las acciones y la cantidad de carbono de los proyectos registrados. Los archivos en s&iacute; no est&aacute;n contenidos en los bloques de la blockchain, los bloques contienen su huella digital (hash). Un algoritmo matem&aacute;tico determina si alg&uacute;n bit del documento original cambia (como en el caso de que el documento sea alterado) registrando una nueva huella digital en la blockchain. La huella digital por s&iacute; sola no permite encontrar los archivos, son el conjunto de archivos y su propia huella digital que permiten tener la certeza que los documentos no fueron alterados y que representan un certificado leg&iacute;timo de bonos de carbono.</p>
<p style={{marginTop: 0, marginBottom: "8pt"}}>Cada certificado cuenta con un c&oacute;digo &uacute;nico y con una serializaci&oacute;n espec&iacute;fica que representa los datos m&aacute;s representativos, as&iacute;:</p>
<p style={{marginTop: "0pt", marginLeft:"36pt", marginBottom: "0pt", textIndent:"-18pt"}}>-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>C&oacute;digo de certificado (c&oacute;digo del proyecto + consecutivo de certificados emitidos)</p>
<p style={{marginTop: "0pt", marginLeft:"36pt", marginBottom: "0pt", textIndent:"-18pt"}}>-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Serial de retiro (COLCX + C&oacute;digo del sector + C&oacute;digo del proyecto + Vintage + Consecutivo de cr&eacute;ditos iniciales + Consecutivo de cr&eacute;ditos finales)</p>
<p style={{marginTop: 0, marginBottom: "8pt"}}>Cualquier duda o requerimiento adicional de informaci&oacute;n puede solicitarse al correo electr&oacute;nico comercial.colcx@canalclima.com</p>
</div>
    </Container>
  );
};

export default AboutUs;
